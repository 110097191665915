<template>
    <div class="home">
        <div class="top_logo flex_r flex_ac flex_jc">
            <img src="@/assets/img/logo.png" class="logo">
        </div>
        <div class="banshi">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div v-for="item in banners" :key="item.index" class="swiper-slide"
                        :style="`background-image:url(${item.bannerUrl})`">
                    </div>
                </div>
                <!-- <div class="swiper-pagination"></div> -->
                <!-- <div class="swiper-button-next"></div> -->
                <!-- <div class="swiper-button-prev"></div> -->
            </div>
            <div class="pagination flex_r flex_ac flex_jc">
                <div :class="['p_item', { active: index == activeIndex }]" v-for="(item, index) in banners" :key="item.index">
                </div>
            </div>
        </div>
        <div class="middle_content">
            <div class="content">
                <div class="title flex_r flex_ac">
                    <img src="@/assets/img/d_img2.png" class="IMG1">
                    <div class="tit">客户端下载</div>
                    <img src="@/assets/img/ico1.png" class="IMG2">
                    <div class="txt">娱乐潮社赏平台，极致娱乐体验</div>
                </div>
                <div class="card flex_r flex_ac flex_jc flex_wrap">
                    <img src="@/assets/img/img1.png" class="img1">
                    <div class="txt_content">
                        <div class="p">娱乐潮社赏</div>
                        <div class="p1">万代正品娱乐潮社赏平台，娱乐潮社赏APP开发有盲盒商城、抽奖功能、线上发货、好友邀请、等功能。</div>
                        <div class="p1">主要玩法有万代一番赏、潮魂扭蛋机、疯狂洞洞乐等特色玩法，给玩家极致的娱乐体验。</div>
                        <div class="p1">平台保证完全的公平、公正、公开，全部玩法随机原则，保障用户的合法权益，并秉持交易自由原则，开发多套交易模式，使用户更方便快捷及安全的在平台交易。</div>
                        <div >客服热线 : 18988792513</div>
                        <div class="p1">咨询邮箱 : chaowanshang@163.com</div>
                        <div class="twocard_down flex_r">
                            <div class="two_card">
                                <img src="@/assets/img/two_caed.jpg" class="two_card_img">
                                <div class="txt">扫码关注公众号</div>
                            </div>
                            <div class="download">
                                <a class="btn flex_r flex_ac flex_jc"
                                    href="https://img.chaoshewang.com/apk/release_v0.0.1.apk">
                                    <img src="@/assets/img/android.png" class="ico1">
                                    <span>Anaroid 下载</span>
                                </a>
                                <a class="btn btn2 flex_r flex_ac flex_jc"
                                    href="http://itunes.apple.com/app/6470879618?mt=8">
                                    <img src="@/assets/img/ios.png" class="ico1 ico2">
                                    <span>IOS 下载</span>
                                </a>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
        <div class="foot">
            <!-- <div class="p">深公网安备110000002000001号 深证ICP030173号</div>
            <div class="p">潮社(深圳)文化传播有限公司 版权所有 Copyright@2023-2023 ice-breaking-power.com</div> -->
            <!-- <div class="p">深公网安备110000002000001号</div> -->
            <!-- <a href="https://beian.miit.gov.cn/" target="_blank" class="p">粤ICP备2023103620号-2</a> -->
            <!-- <div class="p">琼B2-20240342</div> -->
            <a href="https://beian.miit.gov.cn/" target="_blank" class="p">琼ICP备2024025978号-1</a>
            <div class="p">©2024-2024海南立成科技有限公司</div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper'; // 注意引入的是Swiper
import 'swiper/css/swiper.min.css' // 注意这里的引入

export default {
    components: {},
    data() {
        return {
            banners: [],
            swiper: undefined,
            activeIndex: 1,
        };
    },
    created() {
        this.getBanner()
    },
    mounted() { },
    methods: {
        getBanner() {
            this.post("v1/publicize/banner/list").then((res) => {
                this.banners = res.data.banners;
                setTimeout(() => {
                    this.getSwiper()
                }, 100);
            });
        },
        getSwiper() {
            let then = this
            this.swiper = new Swiper(".swiper-container", {
                loop: true, // 无缝
                slidesPerView: 'auto',
                loopedSlides: 3,
                autoplay: { //自动开始
                    delay: 3000, //时间间隔
                    disableOnInteraction: false, //*手动操作轮播图后不会暂停*
                },
                initialSlide: 1,
                centeredSlides: true,
                centeredSlidesBounds: true,
                spaceBetween: 20,
                observer: true,
                observeParents: true,
                on: {
                    slideChange: function () {
                        then.activeIndex = this.realIndex
                        // console.log('改变了，activeIndex为'+this.activeIndex);
                        // console.log('改变了，realIndex为'+this.realIndex);
                    },
                },
                // // 如果需要前进后退按钮
                // navigation: {
                //     nextEl: '.swiper-button-next',
                //     prevEl: '.swiper-button-prev',
                // },
                // // 如果需要分页器
                // pagination: {
                //     el: '.swiper-pagination',
                //     clickable: true, // 分页器可以点击
                // },
            })
        }
    },
};
</script>

<style lang='scss' scoped>

.top_logo {
    padding: 14px 0;

    .logo {
        width: 99px;
        height: 68px;
    }
}

.banshi {
    background-color: #000;
    height: 610px;
    padding: 30px 0 0;

    .swiper-wrapper {
        width: 1920px;
        margin: 0 auto;

        .swiper-slide {
            background-size: 100% 100%;
            height: 550px;
            width: 1200px;
            opacity: 0.6;

            &.swiper-slide-active {
                opacity: 1;
            }

            .el-button {
                z-index: 2;
            }
        }
    }
}

.pagination {
    width: 100%;
    height: 30px;
    background-color: #000;

    .p_item {
        width: 18px;
        height: 8px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.5);
        margin-right: 18px;

        &:last-child {
            margin-right: 0;
        }

        &.active {
            width: 53px;
            background: #fff;
        }
    }
}

.middle_content {
    width: 100%;
    height: 960px;
    background-image: url("@/assets/img/bg_img.png");
    background-size: 100% 100%;

    .content {
        width: 1200px;
        margin: 0 auto;
        padding: 80px 0;
    }

    .title {
        margin-bottom: 30px;

        .IMG1 {
            width: 38px;
            height: 38px;
            margin-right: 10px;
        }

        .tit {
            font-size: 34px;
            color: #4C4B59;
            font-weight: 500;
            margin-right: 20px;
        }

        .IMG2 {
            width: 34px;
            height: 11px;
            margin-right: 10px;
        }

        .txt {
            font-size: 16px;
            color: #9493A4;
        }
    }

    .card {
        width: 100%;
        height: 628px;
        background: #7574D1;
        border-radius: 30px;
        margin: 0 auto;

        .img1 {
            width: 612px;
            height: 693px;
            margin-right: 80px;
        }

        .txt_content {
            width: 420px;
            color: #F3F3FF;
            font-size: 14px;
            line-height: 22px;

            .p {
                font-size: 38px;
                color: #fff;
                margin-bottom: 38px;
            }

            .p1 {
                margin-bottom: 26px;
                // &:last-child{
                //     margin-bottom: 0;
                // }
            }
        }

        .twocard_down {
            .two_card {
                margin-right: 40px;
            }

            .two_card_img {
                width: 110px;
                height: 110px;
                border-radius: 10px;
            }

            .txt {
                text-align: center;
                font-weight: 500;
                color: #B7B6E5;
            }

            .btn {
                width: 153px;
                height: 48px;
                background: #fff;
                color: #01023A;
                font-weight: 800;
                font-size: 14px;
                border-radius: 10px;
                text-decoration: none;
            }

            .btn2 {
                margin-top: 15px;
                // opacity: 0.6;
            }

            .ico1 {
                width: 18px;
                height: 18px;
                margin-right: 8px;
            }

            .ico2 {
                width: 16px;
                height: 19px;
            }
        }
    }
}

.foot {
    padding: 14px 0 20px;
    text-align: center;
    color: #999;
    font-size: 14px;
    line-height: 26px;
}

@media screen and (min-width: 600px) {}

@media screen and (max-width: 600px) {
    .top_logo {
        .logo {
            width: 40px;
            height: 32px;
        }
    }

    .banshi {
        width: 100vw;
        height: 210px;

        .swiper-wrapper {
            width: 390px;

            .swiper-slide {
                width: 318px;
                height: 150px;
            }
        }
    }

    .pagination {
        .p_item {
            width: 10px;
            height: 4px;
            border-radius: 2px;
            margin-right: 8px;

            &.active {
                width: 20px;
            }
        }
    }

    .middle_content {
        height: 916px;

        .content {
            width: 330px;
            padding: 30px 0;
        }

        .title {
            margin-bottom: 20px;

            .IMG1 {
                width: 28px;
                height: 28px;
                margin-right: 6px;
            }

            .IMG2 {
                display: none;
            }

            .tit {
                font-size: 16px;
            }

            .txt {
                font-size: 12px;
            }
        }

        .card {
            height: 750px;
            padding-bottom: 30px;

            .img1 {
                width: 220px;
                height: 250px;
                margin-right: 0;
            }

            .txt_content {
                width: 270px;
                font-size: 12px;

                .p {
                    font-size: 22px;
                }
            }

            .twocard_down {
                .two_card {
                    margin-right: 20px;
                }

                .two_card_img {
                    width: 92px;
                    height: 92px;
                }

                .txt {
                    font-size: 12px;
                }

                .btn {
                    width: 153px;
                    height: 40px;
                    font-size: 12px;
                }

                .btn2 {
                    margin-top: 8px;
                }
            }
        }
    }

    .foot {
        font-size: 12px !important;
        line-height: 16px;

        .p {
            margin-bottom: 6px;
        }
    }
}
</style>